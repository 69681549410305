.Dashboard {
  height: 90vh;
  overflow: auto;
}

.row,
.row2 {
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* #### Media Queries #### */

@media screen and (min-width: 1400px) {
  .row {
    gap: 30px;
  }
}

/*Start Pie Chart*/
.Card {
  width: 24%;
  padding: 20px 40px;
  background-color: #ffffff;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.FirstCart {
  background-color: #a162f7;
}

.CardTitle {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.CardTitleImg {
  width: 38px;
  height: 38px;
}

.TitleName {
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  color: #242731;
}

#first {
  color: #ffffff;
}

#chartdiv1,
#chartdiv2,
#chartdiv3,
#chartdiv4 {
  height: 130px;
  overflow: visible;
}
/* #### Media Queries #### */

@media screen and (max-width: 1200px) {
  .row,
  .row2,
  .row3{
    flex-wrap: wrap;
  }
  .Card {
    width: 23%;
  }
  .TitleName {
    font-size: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .Card {
    width: 48%;
  }
  .TitleName {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .Card {
    width: 100%;
  }
}

/*End Pie Chart*/

/*Start Bar Chart and Graph Chart*/

.BarchartDiv,
.GraphchartDiv {
  width: 49%;
  background-color: #ffffff;
  padding: 15px 25px 0px 25px;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  overflow: hidden;

}

.BarChartTitle,
.GraphChartTitle {
  font-weight: 400px;
  font-size: 20px;
  line-height: 26.04px;
  color: #242731;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.BarChartTitle span,
.GraphChartTitle span {
  font-weight: 400;
}

.BarChartNav,
.GraphChartNav {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ButtomsGroup,
.ButtomsGroup2 {
  height: 20px;
  gap: 10px;
  width: fit-content;
}

.ButtomsGroup button,
.ButtomsGroup2 button {
  width: 50px;
  height: 25px;
  padding: 5px 12px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  cursor: pointer;
}

.ButtomsGroup .FirstButton {
  background-color: #2884ff;
  color: #ffffff;
}

.BarChartNavTitle,
.GraphChartNavTitle {
  width: fit-content;
  color: #5f6165;
  font-size: 14;
  font-weight: 700;
  line-height: 18px;
}

.BarchartDarwer,
.GraphchartDarwer {
  height: 25vh;
  margin-bottom: -20px;
  width: 100%;
}

/* #### Media Queries #### */

@media screen and (max-width: 1200px) {
  .BarchartDiv,
  .GraphchartDiv {
    width: 48%;
  }
}
@media screen and (max-width: 1000px) {
  .BarchartDiv,
  .GraphchartDiv {
    width: 100%;
  }
}
/*End Bar Chart and Graph Chart*/

/*Start Graph Chart special*/
.GraphchartDiv {
  padding: 22px 24px 0px 24px;
}

.GraphChartTitle {
  margin-top: -5px;
}

.ButtomsGroup2 .FirstButton2 {
  background-color: #ff764c;
  color: #ffffff;
}

/*End Graph Chart special*/

/*Start Car Cards*/
.row3 {
  margin-top: 25px;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.CarCard {
  width: 33%;
  padding: 15px 27px;
  min-width: 200px;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}
.CardCarIndex0{
  background-color: #e1dfa4;
}
.CardCarIndex1{
  background-color: #E3ECF1;
}
.CardCarIndex2{
  background-color: #F4E3E5;
}
.topDiv {
  margin-bottom: 15px;
}
.ReloadImg {
  width: 20.2px;
  margin-right: 15px;
}
.topTitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  color: #242731;
}

.centerDivCont{
  width: 100%;
  justify-content: center;
}
.centerDiv {
  width: fit-content;
  margin-bottom: 10px;
}
.BottomDiv {
  flex-direction: column;
  gap: 5px;
}
.BottomDivTitle {
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
}

.BottomDivIconsPrice {
  width: 100%;
  justify-content: space-between;
}

.BottomIcons{
  gap: 20px;
}

.KiloMeter{
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #72767C;
}

.CarCardIcon1 {
  width: 16.67px;
  height: 16.67px;
}
.CarCardIcon2 {
  width: 13.36px;
  height: 13.36px;
}
.CarCardIcon3 {
  width: 10.67px;
  height: 13.36px;
}

.BottomPrice {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #72767c;
}

@media screen and (max-width: 1200px) {
  .centerDivCont{
    width: 100%;
    justify-content: flex-start;
  }
  .CarCard {
    width: 31.5%;
  }
}
@media screen and (max-width: 1000px) {
  .CarCard {
    width: 48%;
  }
}
@media screen and (max-width: 600px) {
  .CarCard {
    width: 100%;
  }
}
/*End Car Cards*/
