.SideBar {
  position: relative;
  background-color: #ffffff;
  padding: 30px 24px;
  width: 18%;
  height: 100%;
  gap: 10px;
}

.SideBar-Content {
  max-width: 90%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.Top {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.LogoDiv {
  height: 31px;
  justify-content: space-between;
  gap: 10px;
}

.LogoImg {
  width: 26px;
  height: 26px;
}

.LogoTitle {
  line-height: 31.25px;
  font-weight: 700;
  font-size: 24px;
  color: #1f2128;
}

.SideBarTopContent,
.SideBarBotomContent {
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.TopContentItemDiv,
.BotomContentItemDiv {
  width: 100%;
  padding: 10px 9px;
  justify-content: flex-start;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.TopContentItemcontainer,
.BotomContentItemcontainer {
  width: fit-content;
  height: 20px;
  gap: 8px;
}

.SideBarContentIcon {
  width: 20px;
}

.itemName {
  font-size: 14px;
  line-height: 18px;
  color: #5f6165;
  cursor: pointer;
}

.SideBarBotomContent {
  gap: 24px;
}

.active{
  background-color: #F3F5F8;
}

@media screen and (min-width: 1400px) {
  .SideBar {
    width: 15%;
  }

  .LogoImg {
    width: 30px;
    height: 30px;
  }

  .LogoTitle {
    line-height: 40px;
    font-size: 30px;
  }

  .SideBarContentIcon {
    width: 22px;
  }

  .itemName {
    font-size: 17px;
  }
}

@media screen and (max-width: 900px) {
  .SideBar {
    width: 9%;
    padding: 8px 8px;
  }
  .LogoTitle,
  .itemName {
    display: none;
  }
  .LogoImg {
    width: 32px;
    height: 32px;
  }
  .SideBarContentIcon {
    width: 24px;
  }
}
@media screen and (max-width: 600px) {
  .SideBar {
    width: 12%;
    padding: 8px 8px;
  }
  .LogoImg {
    width: 30px;
    height: 30px;
  }
  .SideBarContentIcon {
    width: 22px;
  }
}
